import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./core/_services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {
    /*
    this._authService.returnUrl = location.pathname;

    this._authService.csrf().subscribe(() => {
      if (this._authService.token) {
        this._authService.refreshToken().subscribe(() => {
          this._authService.returnToUrl();
        }, () => {
          this._authService.logout();
          this._authService.returnToUrl();
        });
      } else {
        //console.log("does not have token");
        this._authService.returnToUrl();
      }
    });
    */
  }

  ngOnInit() {
    document.addEventListener('DOMContentLoaded', function() {
      const elems = document.querySelectorAll('.dropdown-trigger');
      const instances = M.Dropdown.init(elems, {});
    });
  }
}
