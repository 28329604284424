import { Injectable } from '@angular/core';
import { NotificationService as NS, Position, Animation } from "@progress/kendo-angular-notification";

@Injectable()
export class NotificationService {

  private _hideAfter = 1800;
  private _animation: Animation = { type: 'slide', duration: 400 };
  private _position: Position = { horizontal: 'center', vertical: 'bottom' };

  constructor(
    private _notificationService: NS
  ) { }

  success(message: string) {
    this.show(message, 'success');
  }

  error(message: string) {
    this.show(message, 'error');
  }

  show(message: string, style: 'none' | 'success' | 'warning' | 'error' | 'info') {
    this._notificationService.show({
      content: message,
      hideAfter: this._hideAfter,
      animation: this._animation,
      position: this._position,
      type: { style: style, icon: false }
    });
  }
}
