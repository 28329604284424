import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Tag } from "../_models/tag.model";

@Injectable()
export class TagService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: Tag[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Tag[]>('api/tags')
      .pipe(
        map(data => data.map(d => new Tag().deserialize(d))),
        tap((data: Tag[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  getAll() {
    return this._http.get<Tag[]>(`api/tags`)
      .pipe(map(data => data.map(d => new Tag().deserialize(d))));
  }

  get(uuid: string) {
    return this._http.get<Tag>(`api/tags/${uuid}`)
      .pipe(map(data => new Tag().deserialize(data)));
  }

  create(data: Tag) {
    return this._http.post<Tag>(`api/tags`, data)
      .pipe(map(data => new Tag().deserialize(data)));
  }

  update(uuid: string, data: Tag) {
    return this._http.put<Tag>(`api/tags/${uuid}`, data)
      .pipe(map(data => new Tag().deserialize(data)));
  }

  delete(uuid: string) {
    return this._http.delete(`api/tags/${uuid}`);
  }
}
