import {
  AfterViewInit,
  Component, ElementRef,
  forwardRef, Input,
  OnInit, Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, NgControl,
  RequiredValidator, ValidationErrors,
  Validator, Validators
} from "@angular/forms";


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Control, DomUtil, LatLng, Map, Marker, TileLayer } from "leaflet";
import { NotificationService } from "../../../core/_services/notification.service";

declare const L;

class GeoControl extends Control {
}

@Component({
  selector: 'app-map-locator',
  templateUrl: './map-locator.component.html',
  styleUrls: ['./map-locator.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => MapLocatorComponent)
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => MapLocatorComponent)
  }]
})
export class MapLocatorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('mapContainer', {static: true}) mapContainer: ElementRef;

  @Input('required') required: boolean = false;
  @Input('geocoding') geocoding: boolean = false;
  @Input('geocodingAddress') geocodingAddress: string = "";

  private _map: Map;
  private _marker: Marker;
  private _geoControl: GeoControl;

  constructor(
    private _http: HttpClient,
    private _notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this._map = L.map(this.mapContainer.nativeElement, {
      center: [44.35737244, 11.93743408],
      zoom: 10
    });

    new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this._map);

    const defaultMarker = L.icon({
      iconUrl:       '/assets/marker-icon.png',
      iconRetinaUrl: '/assets/marker-icon-2x.png',
      shadowUrl:     '/assets/marker-shadow.png',
      iconSize:    [25, 41],
      iconAnchor:  [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize:  [41, 41]
    });

    this._marker = new Marker(this._map.getCenter(), {
      draggable: true,
      icon: defaultMarker
    });
    this._marker.addTo(this._map);
    this._marker.on('dragend', () => {
      this.onChange(this._marker.getLatLng());
    });

    // @ts-ignore
    // noinspection TypeScriptValidateTypes
    /*this._geoControl = Control.extend({
      options: {
        position: 'topright'
      },
      onAdd: (map: Map) => {

      },
    });*/

    if (this.geocoding) {
      this._geoControl = new GeoControl();
      this._geoControl.onAdd = (map: Map): HTMLElement => {
        const container = DomUtil.create('button', 'btn');
        container.innerText = "Geolocate";

        container.onclick = () => {
          this.geolocate();
        };

        return container;
      };

      this._map.addControl(this._geoControl);
    }
  }

  geolocate() {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.delete('Access-Control-Request-Headers');
    headers.delete('Authorization');

    const options = {
      withCredentials: false,
      headers: headers,
    };
    this._http.get<any[]>('https://nominatim.openstreetmap.org/search?format=json&limit=1&q=' + this.geocodingAddress, options).subscribe(res => {
      if (res && res.length == 1) {
        const lat = res[0].lat;
        const lon = res[0].lon;

        this.writeValue(new LatLng(lat, lon));
        this.onChange(new LatLng(lat, lon));
        this._notificationService.success("Address found");
      } else {
        this._notificationService.error("Address not found");
      }
    }, err => {
      console.error(err);
      this._notificationService.error("Address not found");
    });
  }

  ngAfterViewInit() {
  }

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  writeValue(value: LatLng): void {
    console.log(value);
    if (value.lat != 0 || value.lng != 0) {
      this._map.panTo(value);
      this._marker.setLatLng(value);
    }
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(ctrl: AbstractControl) {
    if (this.required) {
      return Validators.required(ctrl);
    }
    return null;
  }

}
