import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MediaService {

  constructor(private _http: HttpClient) {
  }

  delete(uuid: string) {
    return this._http.delete(`api/media/${uuid}`);
  }
}
