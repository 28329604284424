import {
  AfterViewInit,
  Component, ElementRef,
  forwardRef, Input,
  OnInit, Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, NgControl,
  RequiredValidator, ValidationErrors,
  Validator, Validators
} from "@angular/forms";

import * as SimpleMDE from "simplemde";

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => MarkdownComponent)
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => MarkdownComponent)
  }]
})
export class MarkdownComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('simplemde', {static: true}) textarea: ElementRef;

  @Input('required') required: boolean = false;
  @Input('toolbar') toolbar: any[] = [];

  private _mde: any;

  constructor() {}

  ngOnInit() {
    let toolbarConfig = [
      "bold", "italic", "heading", "|",
      "quote", "unordered-list", "ordered-list", "|",
      "link", "|",
      "preview", "side-by-side", "fullscreen", "|",
      "guide"
    ];
    if (this.toolbar && this.toolbar.length) {
      toolbarConfig = this.toolbar;
    }
    const options = {
      element: this.textarea.nativeElement,
      hideIcons: ["code", "table"],
      spellChecker: false,
      toolbar: toolbarConfig
    };
    this._mde = new SimpleMDE(options);

    this._mde.codemirror.on("change", () => {
      this.onChange(this._mde.value());
    });

    this._mde.codemirror.on("focus", () => {
      this.onTouched();
    });

  }

  ngAfterViewInit() {}

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this._mde.value(value ? value : "");
  }
  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(ctrl: AbstractControl) {
    if (this.required) {
      return Validators.required(ctrl);
    }
    return null;
  }

}
