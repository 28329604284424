import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../core/_services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {


  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  logout() {
    this._router.navigate(['/auth/logout']);
  }

}
