import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Question } from "../../../../core/_models/question.model";


@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.sass']
})
export class QuestionEditComponent implements OnInit, OnDestroy {

  @Input() model: Question;
  @Output() modelChanged: EventEmitter<Question> = new EventEmitter<Question>();

  public questionFormGroup: FormGroup;

  private _formSubscription: Subscription;

  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    if (this.model) {
      this.updateForm(this.model);
    }
    this._formSubscription = this.questionFormGroup.valueChanges.subscribe(() => {
      this.onModelChanged();
    });
  }

  ngOnDestroy() {
    this._formSubscription.unsubscribe();
  }

  buildForm() {
    this.questionFormGroup = this._formBuilder.group({
      question: ['', Validators.required],
      option_0: ['', Validators.required],
      option_1: ['', Validators.required],
    });
  }

  updateForm(model: Question) {
    this.questionFormGroup.setValue({
      question: model.question,
      option_0: model.option_0,
      option_1: model.option_1,
    });
  }

  onModelChanged() {
    if (this.questionFormGroup.valid) {
      const model = new Question().deserialize(this.questionFormGroup.value);
      this.modelChanged.next(model);
    } else {
      this.modelChanged.next(null);
    }
  }

  get question() {
    return this.questionFormGroup.get("question");
  }
}
