import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { VenueService } from "../../../core/_services/venue.service";

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.sass']
})
export class VenuesComponent implements OnInit {

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(
    private _venueService: VenueService,
    private _router: Router,
  ) {

  }

  ngOnInit() {
    this.view = this._venueService.pipe(map(data => process(data, this.gridState)));
    this._venueService.refresh();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this._venueService.read();
  }

  public editModel({sender, rowIndex, dataItem}) {
    this._router.navigate(['/panel/venue', {uuid: dataItem.uuid}]);
  }


}
