import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {LatLng} from "leaflet";
import { Venue } from "../../../../core/_models/venue.model";


@Component({
  selector: 'app-venue-edit',
  templateUrl: './venue-edit.component.html',
  styleUrls: ['./venue-edit.component.sass']
})
export class VenueEditComponent implements OnInit, OnDestroy {

  @Input() model: Venue;
  @Output() modelChanged: EventEmitter<Venue> = new EventEmitter<Venue>();

  public venueFormGroup: FormGroup;

  private _formSubscription: Subscription;

  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    if (this.model) {
      this.updateForm(this.model);
    }
    this._formSubscription = this.venueFormGroup.valueChanges.subscribe(() => {
      this.onModelChanged();
    });
  }

  ngOnDestroy() {
    this._formSubscription.unsubscribe();
  }

  buildForm() {
    this.venueFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      address: [''],
      city: [''],
      coords: [new LatLng(0,0)],
    });
  }

  updateForm(model: Venue) {
    this.venueFormGroup.setValue({
      name: model.name,
      address: model.address,
      city: model.city,
      coords: model.coords
    });
  }

  onModelChanged() {
    if (this.venueFormGroup.valid) {
      const model = new Venue().deserialize(this.venueFormGroup.value);
      this.modelChanged.next(model);
    } else {
      this.modelChanged.next(null);
    }
  }

  get name() {
    return this.venueFormGroup.get("name");
  }

  get address() {
    return this.venueFormGroup.get("address");
  }

  get city() {
    return this.venueFormGroup.get("city");
  }

  get coords() {
    return this.venueFormGroup.get("coords");
  }
}
