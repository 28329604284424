import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { CancelEvent, SuccessEvent, UploadComponent, UploadEvent } from "@progress/kendo-angular-upload";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Media } from "../../../core/_models/media.model";
import { MediaService } from "../../../core/_services/media.service";
import { NotificationService } from "../../../core/_services/notification.service";

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MediaUploadComponent),
    multi: true
  }]
})
export class MediaUploadComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @ViewChild('kendoUpload', {static: true}) kendoUpload: UploadComponent;

  public uploadUid = null;

  public mediaUrl = 'api/media/images';

  public media: Media = null;

  public touched = false;

  constructor(
    private _mediaService: MediaService,
    private _notificationService: NotificationService
  ) {
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  onUploadStart(e: UploadEvent) {
    this.uploadUid = e.files[0].uid;
  }

  onUploadSuccess(e: SuccessEvent) {
    this.uploadUid = null;

    this.media = new Media().deserialize(e.response.body);
    this.onChange(this.media);

    //console.log('success', e);
  }

  onUploadCancel(e: CancelEvent) {
    this.uploadUid = null;
    console.log('cancelled', e);
  }

  onUploadError(e: ErrorEvent) {
    this.uploadUid = false;
    console.log('error', e);
    this._notificationService.error("Error uploading a file")
  }


  onChange = (delta: any) => {};

  onTouched = () => {
    this.touched = true;
  };
  writeValue(delta: any): void {
    this.media = delta;
  }
  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  delete() {
    if (this.media) {
      this._mediaService.delete(this.media.uuid).subscribe(() => {
        this.media = null;
        this.onChange(this.media);
      }, () => {
        this._notificationService.error("Error deleting a file")
      });
    }
  }
}
