import { Deserializable } from "../_interfaces/deserializable.interface";

export class Media implements Deserializable {

  static readonly TYPE_IMAGE = 1;
  static readonly TYPE_THUMBNAIL = 2;
  static readonly TYPE_PDF = 3;

  uuid: string;
  typeId: number;
  originalName: string;
  extension: string;
  url: string;
  thumbnail: Media;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.typeId = input.typeId;
    this.originalName = input.originalName;
    this.extension = input.extension;
    this.url = input.url;
    this.thumbnail = input.thumbnail;
    return this;
  }

  get hasThumbnail() {
    return !!this.thumbnail
  }

  get isImage() {
    return this.typeId === Media.TYPE_IMAGE;
  }

  get isThumbnail() {
    return this.typeId === Media.TYPE_THUMBNAIL;
  }
}
