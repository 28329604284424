import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Tag } from "../../../core/_models/tag.model";
import { TagService } from "../../../core/_services/tag.service";
import { NotificationService } from "../../../core/_services/notification.service";

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.sass']
})
export class TagComponent implements OnInit {

  public model: Tag = null;
  public modelToBeSaved: Tag = null;
  public isEditing = false;
  public isDeleting = false;

  private _modelUuid: string = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _tagService: TagService,
    private _router: Router,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      if(params['uuid']) {
        this.getModel(params['uuid']);
      } else {
        this.model = null;
        this._modelUuid = null;
        this.changeMode('edit');
      }
    });
  }

  getModel(modelUuid: string) {
    this._tagService.get(modelUuid)
      .subscribe((model) => {
        this.model = model;
        this._modelUuid = model.uuid;
        this.changeMode('view');
      }, () => {
        this._router.navigate(['/panel/not-found']);
      });
  }

  changeMode(mode: 'view' | 'edit') {
    if (mode == 'view') {
      if (!this._modelUuid) {
        this._router.navigate(['/panel/tags']);
      }
      this.isEditing = false;
    }
    if (mode == 'edit') {
      this.isEditing = true;
    }
  }

  saveModel() {
    if (this.modelToBeSaved) {
      if (this.modelToBeSaved.uuid) {
        this._tagService.update(this.modelToBeSaved.uuid, this.modelToBeSaved).subscribe((model) => {
          this.getModel(model.uuid);
          this.changeMode('view');
          this._notificationService.success("Saved");
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      } else {
        this._tagService.create(this.modelToBeSaved).subscribe((model) => {
          this._notificationService.success("Saved");
          this._router.navigate(['/panel/tag', {uuid: model.uuid}]);
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      }
    }
  }

  deleteModel() {
    this.isDeleting = true;
  }

  onModelDeleted(shouldDelete: boolean = false) {
    this.isDeleting = false;
    if (shouldDelete) {
      if (this._modelUuid) {
        this._tagService.delete(this._modelUuid).subscribe(() => {
          this._notificationService.success("Deleted");
          this._router.navigate(['/panel/tags']);
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      } else {
        this._router.navigate(['/panel/tags']);
      }
    }
  }

  onModelChanged(modelToBeSaved: Tag) {
    if (modelToBeSaved) {
      this.modelToBeSaved = modelToBeSaved;
      this.modelToBeSaved.uuid = this._modelUuid;
    } else {
      this.modelToBeSaved = null;
    }

  }

}
