import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import {AnswerService} from "../../../core/_services/answer.service";

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.sass']
})
export class AnswersComponent implements OnInit {

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(
    private _answerService: AnswerService,
    private _router: Router,
  ) {

  }

  ngOnInit() {
    this.view = this._answerService.pipe(map(data => process(data, this.gridState)));
    this._answerService.refresh();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this._answerService.read();
  }
}
