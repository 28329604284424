import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Poll } from '../_models/poll.model';

@Injectable()
export class PollService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: Poll[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Poll[]>('api/polls')
      .pipe(
        map(data => data.map(d => new Poll().deserialize(d))),
        tap((data: Poll[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  getAll() {
    return this._http.get<Poll[]>(`api/polls`)
      .pipe(map(data => data.map(d => new Poll().deserialize(d))));
  }

  get(uuid: string) {
    return this._http.get<Poll>(`api/polls/${uuid}`)
      .pipe(map(data => new Poll().deserialize(data)));
  }

  create(data: Poll) {
    return this._http.post<Poll>(`api/polls`, data)
      .pipe(map(data => new Poll().deserialize(data)));
  }

  update(uuid: string, data: Poll) {
    return this._http.put<Poll>(`api/polls/${uuid}`, data)
      .pipe(map(data => new Poll().deserialize(data)));
  }

  delete(uuid: string) {
    return this._http.delete(`api/polls/${uuid}`);
  }
}
