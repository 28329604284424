import { Deserializable } from "../_interfaces/deserializable.interface";

export class Answer implements Deserializable {
  uuid: string;
  full_name: string;
  contact: string;
  answer: string;
  reason: string;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.full_name = input.full_name;
    this.contact = input.contact;
    this.answer = input.answer;
    this.reason = input.reason;

    return this;
  }
}
