import {Component, Input, OnInit} from '@angular/core';
import { Event } from "../../../../core/_models/event.model";

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.sass']
})
export class EventViewComponent implements OnInit {

  @Input() event: Event;

  constructor() { }

  ngOnInit() {
  }

}
