import { Deserializable } from "../_interfaces/deserializable.interface";

export class Question implements Deserializable {
  uuid: string;
  question: string;
  option_0: string;
  option_1: string;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.question = input.question;
    this.option_0 = input.option_0;
    this.option_1 = input.option_1;

    return this;
  }
}
