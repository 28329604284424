import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../_services/auth.service";
import { HttpResponse } from "../_models/responses/http-response.model";
import { MeResponse } from "../_models/responses/me-response.model";
import { AppConfig } from "../../config/app.config";

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.me().pipe(
      map((user: MeResponse) => true),
      catchError((error: any) => {
        return of(this._router.createUrlTree(AppConfig.loginRoute));
      })
    );
  }
}
