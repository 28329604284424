import { Deserializable } from "../_interfaces/deserializable.interface";
import * as moment from 'moment';

export class Token implements Deserializable {
  tokenRaw: string;

  uuid: string;
  expiresAt: moment.Moment;
  issuedAt: moment.Moment;

  token: string;
  email: string;
  roleId: number;

  deserialize(input: any) {
    this.tokenRaw = input.tokenRaw;

    this.uuid = input['sub'];
    this.expiresAt = moment.unix(input['exp']);
    this.issuedAt = moment.unix(input['iat']);

    this.token = input.token;
    this.email = input.email;
    this.roleId = input['role_id'];

    return this;
  }
}
