import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../../core/_services/notification.service";
import { EventService } from "../../../core/_services/event.service";
import { Event } from "../../../core/_models/event.model";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements OnInit {

  public event: Event = null;
  public eventToBeSaved: Event = null;
  public isEditing = false;
  public isDeleting = false;

  private _eventUuid: string = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _eventService: EventService,
    private _router: Router,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      if(params['uuid']) {
        this.getEvent(params['uuid']);
      } else {
        this.event = null;
        this._eventUuid = null;
        this.changeMode('edit');
      }
    });
  }

  getEvent(eventUuid: string) {
    this._eventService.get(eventUuid)
      .subscribe((event) => {
        this.event = event;
        this._eventUuid = event.uuid;
        this.changeMode('view');
      }, () => {
        this._router.navigate(['/panel/not-found']);
      });
  }

  duplicate() {
    this._eventService.duplicate(this.event.uuid).subscribe(event => {
      this._notificationService.success('Event duplicated');
      this._router.navigate(['/panel/event', event.uuid]);
    }, () => {
      this._notificationService.error('Error duplicating the event.')
    });
  }

  preview() {
    let url = 'https://www.lacorelli.it/event/';
    if (location.hostname == 'localhost') {
      url = 'http://localhost:4200/event/';
    }

    window.open(url + this.event.uuid, '_blank');
  }

  changeMode(mode: 'view' | 'edit') {
    if (mode == 'view') {
      if (!this._eventUuid) {
        this._router.navigate(['/panel/events']);
      }
      this.isEditing = false;
    }
    if (mode == 'edit') {
      this.isEditing = true;
    }
  }

  saveEvent() {
    if (this.eventToBeSaved) {
      if (this.eventToBeSaved.uuid) {
        this._eventService.update(this.eventToBeSaved.uuid, this.eventToBeSaved).subscribe((event) => {
          this.getEvent(event.uuid);
          this.changeMode('view');
          this._notificationService.success("Saved");
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      } else {
        this._eventService.create(this.eventToBeSaved).subscribe((event) => {
          //this.getEvent(event.id);
          //this.changeMode('view');
          this._notificationService.success("Saved");
          this._router.navigate(['/panel/event', event.uuid]);
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      }
    }
  }

  deleteEvent() {
    this.isDeleting = true;
  }

  onDeleteEvent(shouldDelete: boolean = false) {
    this.isDeleting = false;
    if (shouldDelete) {
      if (this._eventUuid) {
        this._eventService.delete(this._eventUuid).subscribe(() => {
          this._notificationService.success("Deleted");
          this._router.navigate(['/panel/events']);
        }, (error) => {
          console.error(error);
          this._notificationService.error("Error");
        });
      } else {
        this._router.navigate(['/panel/events']);
      }
    }
  }

  onEventChanged(eventToBeSaved: Event) {
    if (eventToBeSaved) {
      this.eventToBeSaved = eventToBeSaved;
      this.eventToBeSaved.uuid = this._eventUuid;
    } else {
      this.eventToBeSaved = null;
    }
  }

}
