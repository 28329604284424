export const AppConfig = {
  // JWT
  tokenLocalStorageName: '_t',
  refreshTokenCookieName: '_rt',
  requestWaitingForTokenRefreshTimeout: 2500,
  // XSRF
  xsrfCookieName: '_xsrf',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  requestWaitingForXsrfTimeout: 1500,

  // ROUTES
  defaultRoute: ['/'],
  loginRoute: ['/auth/login'],
  dashboardRoute: ['/dashboard'],
};
