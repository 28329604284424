import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Tag } from "../../../../core/_models/tag.model";


@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.sass']
})
export class TagEditComponent implements OnInit, OnDestroy {

  @Input() model: Tag;
  @Output() modelChanged: EventEmitter<Tag> = new EventEmitter<Tag>();

  public tagFormGroup: FormGroup;

  private _formSubscription: Subscription;

  constructor(
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    if (this.model) {
      this.updateForm(this.model);
    }
    this._formSubscription = this.tagFormGroup.valueChanges.subscribe(() => {
      this.onModelChanged();
    });
  }

  ngOnDestroy() {
    this._formSubscription.unsubscribe();
  }

  buildForm() {
    this.tagFormGroup = this._formBuilder.group({
      name: ['', Validators.required]
    });
  }

  updateForm(model: Tag) {
    this.tagFormGroup.setValue({
      name: model.name
    });
  }

  onModelChanged() {
    if (this.tagFormGroup.valid) {
      const model = new Tag().deserialize(this.tagFormGroup.value);
      this.modelChanged.next(model);
    } else {
      this.modelChanged.next(null);
    }
  }

  get name() {
    return this.tagFormGroup.get("name");
  }
}
