import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../core/_services/auth.service";
import { take, tap } from "rxjs/operators";
import { HttpResponse } from "../../core/_models/responses/http-response.model";
import { LoginResponse } from "../../core/_models/responses/login-response.model";
import { AppConfig } from "../../config/app.config";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"]
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(
    private _httpClient: HttpClient,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    const formValue = this.loginForm.value;

    if (this.loginForm.valid) {
      const formValue = this.loginForm.value;
      this._authService.login(formValue.email, formValue.password).pipe(
        tap((response: LoginResponse) => {
        }),
        take(1),
      ).subscribe((response) => {
        this._router.navigate(AppConfig.defaultRoute);
      }, (error) => {
        console.log(error);
      });
    }

    /*
    if (formValue.email && formValue.password) {
      this._authService.login(formValue.email, formValue.password).subscribe((res) => {
        console.log("User logged in.");
        this._authService.csrf().subscribe(() => {
          this._router.navigate(['/']);
        });
      });
    }
    */
  }

}
