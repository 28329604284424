import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from "../_services/auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, switchMap, take, timeout } from "rxjs/operators";
import { AppConfig } from "../../config/app.config";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

  private _refreshingToken = false;
  private _refreshToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _authService: AuthService,
    private _cookieService: CookieService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("auth/xsrf") || this._cookieService.check(AppConfig.xsrfCookieName)) {
      return next.handle(request);
    }

    if (this._refreshingToken) {
      return this._refreshToken.pipe(
        timeout(AppConfig.requestWaitingForXsrfTimeout),
        filter(result => result !== null),
        take(1),
        switchMap(() => {
          const xsrfToken = this._cookieService.get(AppConfig.xsrfCookieName);
          return next.handle(request.clone({headers: request.headers.set(AppConfig.xsrfHeaderName, xsrfToken)}))
        })
      );
    } else {
      this._refreshingToken = true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this._refreshToken.next(null);

      return this._authService.refreshXsrfToken().pipe(
        switchMap((result: any) => {
          this._refreshingToken = false;
          this._refreshToken.next(true);
          const xsrfToken = this._cookieService.get(AppConfig.xsrfCookieName);
          return next.handle(request.clone({headers: request.headers.set(AppConfig.xsrfHeaderName, xsrfToken)}));
        })
      );
    }
  }
}
