import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Question } from '../_models/question.model';

@Injectable()
export class QuestionService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: Question[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Question[]>('api/questions')
      .pipe(
        map(data => data.map(d => new Question().deserialize(d))),
        tap((data: Question[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  getAll() {
    return this._http.get<Question[]>(`api/questions`)
      .pipe(map(data => data.map(d => new Question().deserialize(d))));
  }

  get(uuid: string) {
    return this._http.get<Question>(`api/questions/${uuid}`)
      .pipe(map(data => new Question().deserialize(data)));
  }

  create(data: Question) {
    return this._http.post<Question>(`api/questions`, data)
      .pipe(map(data => new Question().deserialize(data)));
  }

  update(uuid: string, data: Question) {
    return this._http.put<Question>(`api/questions/${uuid}`, data)
      .pipe(map(data => new Question().deserialize(data)));
  }

  delete(uuid: string) {
    return this._http.delete(`api/questions/${uuid}`);
  }
}
