import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import {Answer} from "../_models/answer.model";

@Injectable()
export class AnswerService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: Answer[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Answer[]>('api/answers')
      .pipe(
        map(data => data.map(d => new Answer().deserialize(d))),
        tap((data: Answer[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  getAll() {
    return this._http.get<Answer[]>(`api/answers`)
      .pipe(map(data => data.map(d => new Answer().deserialize(d))));
  }
}
