import { Deserializable } from "../_interfaces/deserializable.interface";
import { Question } from './question.model';

export class Poll implements Deserializable {
  uuid: string;
  question: Question;
  published: boolean;
  option_0_total: number;
  option_1_total: number;

  question_uuid: string;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.question = input.question ? new Question().deserialize(input.question) : null;

    this.published = input.published;
    this.option_0_total = input.option_0_total;
    this.option_1_total = input.option_1_total;

    this.question_uuid = input.question_uuid ? input.question_uuid : this.question ? this.question.uuid : null;

    return this;
  }
}
