import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from "../_services/auth.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, timeout } from "rxjs/operators";
import { AppConfig } from "../../config/app.config";
import { Token } from "../_models/token.model";
import {NotificationService} from "../_services/notification.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private _refreshingToken = false;
  private _refreshToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _authService: AuthService,
    private _notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(catchError(error => {
      // We don't want to refresh token for some requests like login or refresh token itself
      // So we verify url and we throw an error if it's the case
      if (request.url.includes("auth/refresh") || request.url.includes("auth/login")) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page

        if (request.url.includes("auth/refresh")) {
          console.log("refresh error");
          return this._authService.logout();
        }

        return throwError(error);
      }

      // If error status is different than 401 we want to skip refresh token
      // So we check that and throw the error if it's the case
      if (error.status !== 401 || !this._authService.hasToken()) {
        //console.log("AH");
        //this._authService.logout().subscribe();
        //this._notificationService.error("Authentication Error");
        //return this._authService.sendToLogout();
        //return this._authService.logout();
        return throwError(error);
      }

      if (this._refreshingToken) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this._refreshToken.pipe(
          timeout(AppConfig.requestWaitingForTokenRefreshTimeout),
          filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(this.addAuthenticationToken(request)))
        );
      } else {
        this._refreshingToken = true;
        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this._refreshToken.next(null);

        // Call auth.refreshAccessToken(this is an Observable that will be returned)
        return this._authService
          .refreshAccessToken().pipe(
            catchError((error: any) => {
              this._refreshingToken = false;
              //this._authService.logout();
              return throwError(error);
            }),
            switchMap((token: any) => {
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this._refreshingToken = false;
              this._refreshToken.next(token);

              return next.handle(this.addAuthenticationToken(request));
            })
          );
      }
    }));
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const token: Token = this._authService.getToken();
    //console.log('addAuthenticationToken');
    //console.log(token);

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: 'Bearer ' + token.tokenRaw
      }
    });
  }

}
