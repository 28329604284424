import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { map } from "rxjs/operators";
import { Event } from "../../../core/_models/event.model";
import { Router } from "@angular/router";
import { EventService } from "../../../core/_services/event.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(
    private _eventService: EventService,
    private _router: Router,
  ) {

  }

  ngOnInit() {
    this.view = this._eventService.pipe(map(data => process(data, this.gridState)));
    this._eventService.refresh();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this._eventService.read();
  }

  public editEvent({sender, rowIndex, dataItem}) {
    this._router.navigate(['/panel/event', dataItem.uuid]);
  }


}
