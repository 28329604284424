import { Deserializable } from "../_interfaces/deserializable.interface";

export class User implements Deserializable {
  uuid: string;
  email: string;
  roleId: number;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.email = input.email;
    this.roleId = input.roleId;

    return this;
  }

}
