import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Venue } from "../_models/venue.model";

@Injectable()
export class VenueService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: any[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Venue[]>('api/venues')
      .pipe(
        map(data => data.map(d => new Venue().deserialize(d))),
        tap((data: Venue[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  getAll() {
    return this._http.get<Venue[]>(`api/venues`)
      .pipe(map(data => data.map(d => new Venue().deserialize(d))));
  }

  get(uuid: string) {
    return this._http.get<Venue>(`api/venues/${uuid}`)
      .pipe(map(data => new Venue().deserialize(data)));
  }

  create(data: Venue) {
    return this._http.post<Venue>(`api/venues`, data)
      .pipe(map(data => new Venue().deserialize(data)));
  }

  update(uuid: string, data: Venue) {
    return this._http.put<Venue>(`api/venues/${uuid}`, data)
      .pipe(map(data => new Venue().deserialize(data)));
  }

  delete(uuid: string) {
    return this._http.delete(`api/venues/${uuid}`);
  }
}
