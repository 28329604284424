import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../core/_services/auth.service";
import { Router } from "@angular/router";
import { AppConfig } from "../../config/app.config";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._authService.logout().subscribe((r) => {
      this._router.navigate(AppConfig.defaultRoute);
    }, (e) => {});
  }

}
