import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from "./core/_guards/authenticated.guard";
import { PollComponent } from './panel/content/poll/poll.component';
import { PollsComponent } from './panel/content/polls/polls.component';
import { QuestionComponent } from './panel/content/question/question.component';
import { QuestionsComponent } from './panel/content/questions/questions.component';
import { PanelComponent } from "./panel/panel.component";
import { EventsComponent } from "./panel/content/events/events.component";
import { EventComponent } from "./panel/content/event/event.component";
import { VenuesComponent } from "./panel/content/venues/venues.component";
import { VenueComponent } from "./panel/content/venue/venue.component";
import { TagsComponent } from "./panel/content/tags/tags.component";
import { TagComponent } from "./panel/content/tag/tag.component";
import { NotFoundComponent } from "./panel/content/not-found/not-found.component";
import { AuthComponent } from "./auth/auth.component";
import { NotAuthenticatedGuard } from "./core/_guards/not-authenticated.guard";
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import {AnswersComponent} from "./panel/content/answers/answers.component";

const routes: Routes = [
  {
    path: 'panel',
    canActivate: [AuthenticatedGuard],
    component: PanelComponent,
    children: [
      {
        path: 'events',
        component: EventsComponent
      }, {
        path: 'event/:uuid',
        component: EventComponent
      }, {
        path: 'event',
        component: EventComponent
      }, {
        path: 'venues',
        component: VenuesComponent
      }, {
        path: 'venue/:uuid',
        component: VenueComponent
      }, {
        path: 'venue',
        component: VenueComponent
      }, {
        path: 'tags',
        component: TagsComponent
      }, {
        path: 'tags/:uuid',
        component: TagComponent
      }, {
        path: 'tag',
        component: TagComponent
      }, {
        path: 'questions',
        component: QuestionsComponent
      }, {
        path: 'questions/:uuid',
        component: QuestionComponent
      }, {
        path: 'question',
        component: QuestionComponent
      }, {
        path: 'polls',
        component: PollsComponent
      }, {
        path: 'polls/:uuid',
        component: PollComponent
      }, {
        path: 'poll',
        component: PollComponent
      }, {
        path: 'answers',
        component: AnswersComponent
      }, {
        path: 'not-found',
        component: NotFoundComponent
      }, {
        path: '',
        redirectTo: '/panel/events',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
      },
      {
        path: 'login',
        canActivate: [NotAuthenticatedGuard],
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
    ]
  },
  {
    path: '',
    redirectTo: '/panel/events',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
