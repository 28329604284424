import {Component, Input, OnInit} from '@angular/core';
import { Venue } from "../../../../core/_models/venue.model";

@Component({
  selector: 'app-venue-view',
  templateUrl: './venue-view.component.html',
  styleUrls: ['./venue-view.component.sass']
})
export class VenueViewComponent implements OnInit {

  @Input() model: Venue;

  constructor() { }

  ngOnInit() {
  }

}
