import { Deserializable } from "../../_interfaces/deserializable.interface";

export class MeResponse implements Deserializable {
  uuid: string;
  email: string;
  roleId: number;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.email = input.email;
    this.roleId = input['role_id'];

    return this;
  }
}
