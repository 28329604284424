import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Event } from "../../../../core/_models/event.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {groupBy, GroupResult, orderBy} from "@progress/kendo-data-query";
import { Tag } from "../../../../core/_models/tag.model";
import { VenueService } from "../../../../core/_services/venue.service";
import { TagService } from "../../../../core/_services/tag.service";


@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.sass']
})
export class EventEditComponent implements OnInit, OnDestroy {

  @Input() event: Event;
  @Output() eventChanged: EventEmitter<Event> = new EventEmitter<Event>();

  public eventFormGroup: FormGroup;
  public venues: GroupResult[];
  public tags: Tag[];

  private _formSubscription: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _venueService: VenueService,
    private _tagService: TagService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this._venueService.getAll().subscribe(venues => {
      this.setVenues(venues);
      this._tagService.getAll().subscribe(tags => {
        this.setTags(tags);

        if (this.event) {
          this.updateForm(this.event);
        }
        this._formSubscription = this.eventFormGroup.valueChanges.subscribe(() => {
          this.onEventChanged();
        });
      });
    });
  }

  ngOnDestroy() {
    this._formSubscription.unsubscribe();
  }

  setVenues(venues: any[]) {
    this.venues = groupBy(orderBy(venues, [{ field: "city", dir: "asc" }]), [{field: "city"}]);
  }

  setTags(tags: any[]) {
    this.tags = orderBy(tags, [{ field: "name", dir: "asc" }]);
  }

  buildForm() {
    this.eventFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      subtitle: [''],
      content_main: ['', Validators.required],
      content_info: [''],
      published: [''],
      cover: [''],
      date: [''],
      time: [''],
      venue_uuid: [''],
      tags: [''],
      ext_facebook: [''],
      ext_website: [''],
      ext_ticket: [''],
      ext_programme: [''],
    });
  }

  updateForm(event: Event) {
    this.eventFormGroup.setValue({
      title: event.title,
      subtitle: event.subtitle,
      content_main: event.content_main,
      content_info: event.content_info,
      published: event.published,
      cover: event.cover,
      date: event.date,
      time: event.time,
      venue_uuid: event.venue ? event.venue.uuid : null,
      tags: event.tags,
      ext_facebook: event.ext_facebook,
      ext_website: event.ext_website,
      ext_ticket: event.ext_ticket,
      ext_programme: event.ext_programme,
    });
  }

  onEventChanged() {
    if (this.eventFormGroup.valid) {
      const event = new Event().deserialize(this.eventFormGroup.value);
      this.eventChanged.next(event);
    } else {
      this.eventChanged.next(null);
    }
  }

  get title() {
    return this.eventFormGroup.get("title");
  }
  get content_main() {
    return this.eventFormGroup.get("content_main");
  }
  get date() {
    return this.eventFormGroup.get("date");
  }
  get time() {
    return this.eventFormGroup.get("time");
  }
}
