import { Deserializable } from "../_interfaces/deserializable.interface";
import { Media } from "./media.model";

import * as moment from 'moment';
import { Venue } from "./venue.model";
import { Tag } from "./tag.model";

export class Event implements Deserializable {
  uuid: string;
  title: string;
  subtitle: string;
  date: string;
  time: string;
  content_main: string;
  content_main_html: string;
  content_info: string;
  content_info_html: string;
  published: boolean;

  ext_facebook: string;
  ext_website: string;
  ext_ticket: string;
  ext_programme: string;

  tags: Tag[];

  cover: Media;
  venue: Venue;

  date_moment: moment.Moment;
  time_moment: moment.Moment;

  date_output: string;
  time_output: string;

  cover_uuid: string;
  venue_uuid: string;


  deserialize(input: any) {
    this.uuid = input.uuid;
    this.title = input.title;
    this.subtitle = input.subtitle;
    this.date = input.date;
    this.time = input.time;
    this.content_main = input.content_main;
    this.content_main_html = input.content_main_html;
    this.content_info = input.content_info;
    this.content_info_html = input.content_info_html;
    this.published = input.published;

    this.ext_facebook = input.ext_facebook || '';
    this.ext_website = input.ext_website || '';
    this.ext_ticket = input.ext_ticket || '';
    this.ext_programme = input.ext_programme || '';

    this.cover = input.cover ? new Media().deserialize(input.cover) : null;
    this.venue = input.venue ? new Venue().deserialize(input.venue) : null;

    this.cover_uuid = input.cover_uuid ? input.cover_uuid : this.cover ? this.cover.uuid : null;
    this.venue_uuid = input.venue_uuid ? input.venue_uuid : this.venue ? this.venue.uuid : null;

    this.tags = input.tags ? input.tags.map(tag => new Tag().deserialize(tag)) : [];

    if (this.date) {
      this.date_moment = moment(this.date, "YYYY-MM-DD");
      this.date_output = this.date_moment.format("DD MMM YYYY");
    }

    if (this.time) {
      this.time_moment = moment(this.time, "HH:mm:ss");
      this.time_output = this.time_moment.format("HH:mm");
    }

    return this;
  }
}
