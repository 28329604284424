import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [AuthComponent, LoginComponent, LogoutComponent],
  imports: [
    SharedModule
  ]
})
export class AuthModule { }
