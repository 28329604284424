import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { PollService } from "../../../core/_services/poll.service";

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.sass']
})
export class PollsComponent implements OnInit {

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(
    private _pollService: PollService,
    private _router: Router,
  ) {

  }

  ngOnInit() {
    this.view = this._pollService.pipe(map(data => process(data, this.gridState)));
    this._pollService.refresh();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this._pollService.read();
  }

  public editModel({sender, rowIndex, dataItem}) {
    this._router.navigate(['/panel/poll', {uuid: dataItem.uuid}]);
  }


}




