import { Component, Input, OnInit } from "@angular/core";
import { Poll } from "../../../../core/_models/poll.model";

@Component({
  selector: 'app-poll-view',
  templateUrl: './poll-view.component.html',
  styleUrls: ['./poll-view.component.sass']
})
export class PollViewComponent implements OnInit {

  @Input() model: Poll;

  constructor() { }

  ngOnInit() {
  }

}
