import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthService } from "./_services/auth.service";
import { EventService } from "./_services/event.service";
import { NotificationService } from "./_services/notification.service";
import { PollService } from './_services/poll.service';
import { QuestionService } from './_services/question.service';
import { TagService } from "./_services/tag.service";
import { VenueService } from "./_services/venue.service";
import { MediaService } from "./_services/media.service";
import { AuthenticatedGuard } from "./_guards/authenticated.guard";
import { NotAuthenticatedGuard } from "./_guards/not-authenticated.guard";
import { OptionalGuard } from "./_guards/optional.guard";
import { XsrfInterceptor } from "./_interceptors/xsrf.interceptor";
import { TokenInterceptor } from "./_interceptors/token.interceptor";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import {AnswerService} from "./_services/answer.service";

@NgModule({
  declarations: [
  ],
  imports: [],
  providers: [
    AuthService,
    EventService,
    NotificationService,
    TagService,
    VenueService,
    MediaService,
    QuestionService,
    PollService,
    AnswerService,

    CookieService,

    AuthenticatedGuard,
    NotAuthenticatedGuard,
    OptionalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: {config: {}}},
  ],
  exports: []
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
