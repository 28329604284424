import { Component, Input, OnInit } from "@angular/core";
import { Tag } from "../../../../core/_models/tag.model";

@Component({
  selector: 'app-tag-view',
  templateUrl: './tag-view.component.html',
  styleUrls: ['./tag-view.component.sass']
})
export class TagViewComponent implements OnInit {

  @Input() model: Tag;

  constructor() { }

  ngOnInit() {
  }

}
