import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


import { MediaUploadComponent } from "./_forms/media-upload/media-upload.component";
import { DatePickerComponent } from "./_forms/date-picker/date-picker.component";
import { MapLocatorComponent } from "./_forms/map-locator/map-locator.component";
import { MarkdownComponent } from "./_forms/markdown/markdown.component";
import { TimePickerComponent } from "./_forms/time-picker/time-picker.component";

import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { UploadModule } from "@progress/kendo-angular-upload";
import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";

@NgModule({
  declarations: [
    MediaUploadComponent,
    DatePickerComponent,
    MapLocatorComponent,
    MarkdownComponent,
    TimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: '_xsrf',
      headerName: 'X-XSRF-TOKEN',
    }),

    BrowserAnimationsModule,
    ButtonsModule,
    UploadModule,
    GridModule,
    InputsModule,
    NotificationModule,
    DialogsModule,
    DropDownsModule

  ],
  providers: [
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: {config: {}}},
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MediaUploadComponent,
    DatePickerComponent,
    MapLocatorComponent,
    MarkdownComponent,
    TimePickerComponent,

    BrowserAnimationsModule,
    ButtonsModule,
    UploadModule,
    GridModule,
    InputsModule,
    NotificationModule,
    DialogsModule,
    DropDownsModule
  ]
})
export class SharedModule { }
