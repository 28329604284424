import {
  AfterViewInit,
  Component, ElementRef,
  forwardRef, Input,
  OnInit, Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, NgControl,
  RequiredValidator, ValidationErrors,
  Validator, Validators
} from "@angular/forms";

import { Timepicker } from "materialize-css";
import * as moment from 'moment';

declare const M;

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TimePickerComponent)
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => TimePickerComponent)
  }]
})
export class TimePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('timepicker', {static: true}) timepicker: ElementRef;

  @Input('required') required: boolean = false;

  private _tp: Timepicker;

  constructor() {
  }

  ngOnInit() {
    this._tp = Timepicker.init(this.timepicker.nativeElement, {
      twelveHour: false,
      onCloseEnd: () => {
        console.log(this._tp.time);
        this.onChange(this._tp.time);
      },
      onOpenStart: () => this.onTouched()
    });
  }

  ngAfterViewInit() {
  }

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  writeValue(value: any): void {
    if (value) {
      this.timepicker.nativeElement.value = moment(value, "HH:mm:ss").format("HH:mm");
    }
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(ctrl: AbstractControl) {
    if (this.required) {
      return Validators.required(ctrl);
    }
    return null;
  }

}
