import { Component, Input, OnInit } from "@angular/core";
import { Question } from "../../../../core/_models/question.model";

@Component({
  selector: 'app-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.sass']
})
export class QuestionViewComponent implements OnInit {

  @Input() model: Question;

  constructor() { }

  ngOnInit() {
  }

}
