import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";

import { Event } from "../_models/event.model"

@Injectable()
export class EventService extends BehaviorSubject<any[]> {

  constructor(private _http: HttpClient) {
    super([]);
  }

  private _data: any[] = [];

  read() {
    if (this._data.length) {
      return super.next(this._data);
    }
    this._http.get<Event[]>('api/events')
      .pipe(
        map(data => data.map(d => new Event().deserialize(d))),
        tap((data: Event[]) => this._data = data)
      )
      .subscribe(data => {
        super.next(this._data);
      });
  }

  refresh() {
    this.reset();
    this.read();
  }

  reset() {
    this._data = [];
  }

  get(uuid: string) {
    return this._http.get<Event>(`api/events/${uuid}`)
      .pipe(map(event => new Event().deserialize(event)));
  }

  create(event: Event) {
    return this._http.post<Event>(`api/events`, event)
      .pipe(map(event => new Event().deserialize(event)));
  }

  update(uuid: string, event: Event) {
    return this._http.put<Event>(`api/events/${uuid}`, event)
      .pipe(map(event => new Event().deserialize(event)));
  }

  delete(uuid: string) {
    return this._http.delete(`api/events/${uuid}`);
  }

  duplicate(uuid: string) {
    return this._http.post<Event>(`api/events/duplicate`, {uuid: uuid})
      .pipe(map(event => new Event().deserialize(event)));
  }
}
