import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { finalize, map, take, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { User } from "../_models/user.model";
import { HttpResponse } from "../_models/responses/http-response.model";
import { LoginResponse } from "../_models/responses/login-response.model";
import { RefreshResponse } from "../_models/responses/refresh-response.model";
import { MeResponse } from "../_models/responses/me-response.model";
import { Token } from "../_models/token.model";
import { AppConfig } from "../../config/app.config";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private _httpClient: HttpClient,
    private _jwtService: JwtHelperService
  ) {

  }

  public login(email: string, password: string): Observable<LoginResponse> {
    return this._httpClient.post<LoginResponse>('api/auth/login', {email, password}).pipe(
      tap((response: LoginResponse) => {
        this.setToken(response.token);
      }),
      take(1),
    );
  }

  public logout(): Observable<any> {
    return this._httpClient.post('api/auth/logout', {}).pipe(
      take(1),
      finalize(() => {
        this.unsetToken();
      })
    );
  }

  public refreshXsrfToken(): Observable<any> {
    return this._httpClient.get<any>('api/auth/xsrf', {}).pipe(
      take(1),
    );
  }

  public refreshAccessToken(): Observable<RefreshResponse> {
    return this._httpClient.post<RefreshResponse>('api/auth/refresh', {}).pipe(
      tap((response: RefreshResponse) => {
        this.setToken(response.token);
      }),
      take(1),
    );
  }

  public me(): Observable<MeResponse> {
    return this._httpClient.get<MeResponse>('api/auth/me').pipe(
      map((response) => {
        let newRes = new MeResponse();
        newRes = new MeResponse().deserialize(response);
        return newRes;
      }),
      tap((response: MeResponse) => {
        this.user.next(new User().deserialize(response));
      }),
      take(1),
    );
  }

  public getAccessToken(): string {
    return this.getToken().token;
  }

  public getToken(): Token {
    const tokenRaw = localStorage.getItem(AppConfig.tokenLocalStorageName);
    if (!tokenRaw) {
      return null;
    }
    const tokenRawObj = this._jwtService.decodeToken(tokenRaw);
    tokenRawObj['tokenRaw'] = tokenRaw;

    return new Token().deserialize(tokenRawObj);
  }

  public setToken(tokenRaw: string) {
    localStorage.setItem(AppConfig.tokenLocalStorageName, tokenRaw);
  }

  public unsetToken() {
    localStorage.removeItem(AppConfig.tokenLocalStorageName);

    // Not sure about this
    this.user.next(null);
  }

  public hasToken() {
    return !!this.getToken();
  }
}
