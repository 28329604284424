import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { groupBy, GroupResult, orderBy } from '@progress/kendo-data-query';
import { Subscription } from "rxjs";
import { Poll } from "../../../../core/_models/poll.model";
import { QuestionService } from '../../../../core/_services/question.service';


@Component({
  selector: 'app-poll-edit',
  templateUrl: './poll-edit.component.html',
  styleUrls: ['./poll-edit.component.sass']
})
export class PollEditComponent implements OnInit, OnDestroy {

  @Input() model: Poll;
  @Output() modelChanged: EventEmitter<Poll> = new EventEmitter<Poll>();


  public questions: GroupResult[];

  public pollFormGroup: FormGroup;

  private _formSubscription: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _questionService: QuestionService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this._questionService.getAll().subscribe(questions => {
      this.setQuestions(questions);

      if (this.model) {
        this.updateForm(this.model);
      }

      this._formSubscription = this.pollFormGroup.valueChanges.subscribe(() => {
        this.onModelChanged();
      });

    });
  }

  ngOnDestroy() {
    this._formSubscription.unsubscribe();
  }

  setQuestions(questions: any[]) {
    this.questions = orderBy(questions, [{ field: "question", dir: "asc" }]);
  }

  buildForm() {
    this.pollFormGroup = this._formBuilder.group({
      question_uuid: ['', Validators.required],
      published: [false],
    });
  }

  updateForm(model: Poll) {
    this.pollFormGroup.setValue({
      question_uuid: model.question.uuid,
      published: model.published,
    });
  }

  onModelChanged() {
    if (this.pollFormGroup.valid) {
      const model = new Poll().deserialize(this.pollFormGroup.value);
      this.modelChanged.next(model);
    } else {
      this.modelChanged.next(null);
    }
  }
}
