import { NgModule } from '@angular/core';
import { PollEditComponent } from './content/poll/poll-edit/poll-edit.component';
import { PollViewComponent } from './content/poll/poll-view/poll-view.component';
import { PollComponent } from './content/poll/poll.component';
import { PollsComponent } from './content/polls/polls.component';
import { QuestionEditComponent } from './content/question/question-edit/question-edit.component';
import { QuestionViewComponent } from './content/question/question-view/question-view.component';
import { QuestionComponent } from './content/question/question.component';
import { PanelComponent } from './panel.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContentComponent } from './content/content.component';
import { EventsComponent } from './content/events/events.component';
import { SharedModule } from "../shared/shared.module";
import { EventEditComponent } from './content/event/event-edit/event-edit.component';
import { NotFoundComponent } from './content/not-found/not-found.component';
import { EventComponent } from './content/event/event.component';
import { EventViewComponent } from './content/event/event-view/event-view.component';
import { VenuesComponent } from "./content/venues/venues.component";
import { VenueComponent } from "./content/venue/venue.component";
import { VenueEditComponent } from "./content/venue/venue-edit/venue-edit.component";
import { VenueViewComponent } from "./content/venue/venue-view/venue-view.component";
import { TagsComponent } from './content/tags/tags.component';
import { TagComponent } from './content/tag/tag.component';
import { TagEditComponent } from './content/tag/tag-edit/tag-edit.component';
import { TagViewComponent } from './content/tag/tag-view/tag-view.component';
import { QuestionsComponent } from './content/questions/questions.component';
import {AnswersComponent} from "./content/answers/answers.component";

@NgModule({
  declarations: [
    PanelComponent,
    HeaderComponent,
    SidebarComponent,
    ContentComponent,
    NotFoundComponent,
    EventsComponent,
    EventComponent,
    EventEditComponent,
    EventViewComponent,
    VenuesComponent,
    VenueComponent,
    VenueEditComponent,
    VenueViewComponent,
    TagsComponent,
    TagComponent,
    TagEditComponent,
    TagViewComponent,
    QuestionsComponent,
    QuestionComponent,
    QuestionEditComponent,
    QuestionViewComponent,
    PollsComponent,
    PollComponent,
    PollEditComponent,
    PollViewComponent,
    AnswersComponent,
  ],
  imports: [
    SharedModule
  ]
})
export class PanelModule { }
