import {
  AfterViewInit,
  Component, ElementRef,
  forwardRef, Input,
  OnInit, Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, NgControl,
  RequiredValidator, ValidationErrors,
  Validator, Validators
} from "@angular/forms";


import {Datepicker} from "materialize-css";
import * as moment from 'moment';

declare const M;

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DatePickerComponent)
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => DatePickerComponent)
  }]
})
export class DatePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('datepicker', {static: true}) datepicker: ElementRef;

  @Input('required') required: boolean = false;

  private _dp: Datepicker;

  constructor() {
  }

  ngOnInit() {
    this._dp = Datepicker.init(this.datepicker.nativeElement, {
      format: 'dd mmm yyyy',
      firstDay: 1,
      onSelect: (date) => {
        const d = moment(date).format("YYYY-MM-DD");
        this.onChange(d);
      },
      onOpen: () => this.onTouched()
    });
  }

  ngAfterViewInit() {
  }

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  writeValue(value: any): void {
    const d = moment(value).toDate();
    this._dp.setDate(d);
    this.datepicker.nativeElement.value = this._dp.toString();
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(ctrl: AbstractControl) {
    if (this.required) {
      return Validators.required(ctrl);
    }
    return null;
  }

}
