import { Deserializable } from "../_interfaces/deserializable.interface";

export class Tag implements Deserializable {
  uuid: string;
  name: string;

  deserialize(input: any) {
    this.uuid = input.uuid;
    this.name = input.name;

    return this;
  }
}
